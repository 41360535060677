export const EditorialSectionMockData = {
  1: {
    data: {
      id: 1,
      attributes: {
        layout: 'invert_cols',
        bg: 'dark',
        textAlign: 'left',
        openingTitle: 'La ricerca',
        title:
          'L’<span class="Emphasized">impegno</span> costante dei ricercatori e delle ricercatrici AIRC',
        paragraph:
          'Raggiungere la quota dei 10.000€ permetterà a Maria di concludere il suo ciclo di chemioterapia',
        image: {
          id: 1,
          name: 'example.jpg',
          alternativeText: 'An example image',
          caption: 'Example caption',
          width: 1200,
          height: 1200,
          // formats: {
          //   thumbnail: {
          //     name: 'thumbnail_example.jpg',
          //     hash: 'thumbnail_example_hash',
          //     ext: '.jpg',
          //     mime: 'image/jpeg',
          //     width: 156,
          //     height: 117,
          //     size: 3.1,
          //     path: null,
          //     url: '/uploads/thumbnail_example_hash.jpg',
          //   },
          //   large: {
          //     name: 'large_example.jpg',
          //     hash: 'large_example_hash',
          //     ext: '.jpg',
          //     mime: 'image/jpeg',
          //     width: 800,
          //     height: 600,
          //     size: 40.5,
          //     path: null,
          //     url: '/uploads/large_example_hash.jpg',
          //   },
          // },
          hash: 'example_hash',
          ext: '.jpg',
          mime: 'image/jpeg',
          size: 45.1,
          url: 'https://picsum.photos/1200/1202',
          previewUrl: null,
          provider: 'local',
          provider_metadata: null,
          created_at: '2023-10-01T12:34:56.789Z',
          updated_at: '2023-10-01T12:34:56.789Z',
        },
        ctaButton: {
          label: 'Sostieni la ricerca',
          url: '/dona',
        },
        createdAt: '2023-10-01T12:34:56.789Z',
        updatedAt: '2023-10-01T12:34:56.789Z',
        publishedAt: '2023-10-01T12:34:56.789Z',
      },
    },
    meta: {},
  },
  2: {
    data: {
      id: 2,
      attributes: {
        layout: null,
        bg: null,
        textAlign: 'left',
        openingTitle: 'I medici',
        title:
          '<span class="Emphasized">Ogni giorno</span> migliaia di medici sono impegnati su tutto il territorio',
        paragraph:
          'Il loro impegno è fondamentale per i pazienti che contano su di loro.',
        image: {
          id: 1,
          name: 'example.jpg',
          alternativeText: 'An example image',
          caption: 'Example caption',
          width: 1200,
          height: 1200,
          // formats: {
          //   thumbnail: {
          //     name: 'thumbnail_example.jpg',
          //     hash: 'thumbnail_example_hash',
          //     ext: '.jpg',
          //     mime: 'image/jpeg',
          //     width: 156,
          //     height: 117,
          //     size: 3.1,
          //     path: null,
          //     url: '/uploads/thumbnail_example_hash.jpg',
          //   },
          //   large: {
          //     name: 'large_example.jpg',
          //     hash: 'large_example_hash',
          //     ext: '.jpg',
          //     mime: 'image/jpeg',
          //     width: 800,
          //     height: 600,
          //     size: 40.5,
          //     path: null,
          //     url: '/uploads/large_example_hash.jpg',
          //   },
          // },
          hash: 'example_hash',
          ext: '.jpg',
          mime: 'image/jpeg',
          size: 45.1,
          url: 'https://picsum.photos/1200/1203',
          previewUrl: null,
          provider: 'local',
          provider_metadata: null,
          created_at: '2023-10-01T12:34:56.789Z',
          updated_at: '2023-10-01T12:34:56.789Z',
        },
        ctaButton: {
          label: 'Dona ora',
          url: '/dona',
        },
        testimonialCard: {
          data: {
            id: 1,
            attributes: {
              title: 'Marco',
              subtitle: 'San Raffaele di Milano',
              image: {
                url: 'https://i.pravatar.cc/50',
              },
              text: 'Ogni giorno da circa 20 anni do il meglio per fare in modo che le persone abbiano una possibilità contro il cancro.',
              createdAt: '2023-10-01T12:34:56.789Z',
              updatedAt: '2023-10-01T12:34:56.789Z',
              publishedAt: '2023-10-01T12:34:56.789Z',
            },
          },
        },
        createdAt: '2023-10-01T12:34:56.789Z',
        updatedAt: '2023-10-01T12:34:56.789Z',
        publishedAt: '2023-10-01T12:34:56.789Z',
      },
    },
    meta: {},
  },
}
