export const HeroSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: null,
      textAlign: null,
      title:
        'Unisciti a oltre +<span class="Emphasized">3000</span> donatori ogni anno',
      paragraph:
        'Contribuisci alla ricerca contro il cancro e salva milioni di persone ogni anno.',
      // If the layout is 'single_col' this image will have
      // an appropriate size for desktop fullscreen use
      image: {
        id: 1,
        name: 'example.jpg',
        alternativeText: 'An example image',
        caption: 'Example caption',
        width: 1200,
        height: 1200,
        // formats: {
        //   thumbnail: {
        //     name: 'thumbnail_example.jpg',
        //     hash: 'thumbnail_example_hash',
        //     ext: '.jpg',
        //     mime: 'image/jpeg',
        //     width: 156,
        //     height: 117,
        //     size: 3.1,
        //     path: null,
        //     url: '/uploads/thumbnail_example_hash.jpg',
        //   },
        //   large: {
        //     name: 'large_example.jpg',
        //     hash: 'large_example_hash',
        //     ext: '.jpg',
        //     mime: 'image/jpeg',
        //     width: 800,
        //     height: 600,
        //     size: 40.5,
        //     path: null,
        //     url: '/uploads/large_example_hash.jpg',
        //   },
        // },
        hash: 'example_hash',
        ext: '.jpg',
        mime: 'image/jpeg',
        size: 45.1,
        url: 'https://picsum.photos/1200/1200',
        previewUrl: null,
        provider: 'local',
        provider_metadata: null,
        created_at: '2023-10-01T12:34:56.789Z',
        updated_at: '2023-10-01T12:34:56.789Z',
      },
      ctaButton: {
        label: 'Dai il tuo contributo',
        url: '/dona',
      },
      progressCard: {
        figures: {
          part: 6500,
          whole: 10000,
          unit: '€',
        },
        ctaMessage: '+1000 hanno già donato',
      },
      // This should only be populated when the layout is 'single_col';
      // this field and `progressCard` should be mutually exclusive.
      testimonials: [
        {
          data: {
            id: 1,
            attributes: {
              title: 'Marco',
              subtitle: 'San Raffaele di Milano',
              image: {
                url: 'https://i.pravatar.cc/50',
              },
              text: 'Ogni giorno da circa 20 anni do il meglio per fare in modo che le persone abbiano una possibilità contro il cancro.',
              createdAt: '2023-10-01T12:34:56.789Z',
              updatedAt: '2023-10-01T12:34:56.789Z',
              publishedAt: '2023-10-01T12:34:56.789Z',
            },
          },
        },
        {
          data: {
            id: 1,
            attributes: {
              title: 'Denis',
              subtitle: 'Ducimus Dolores Iure Voluptatem',
              image: {
                url: 'https://i.pravatar.cc/51',
              },
              text: 'Id Nesciunt Aut Tenetur porro deleniti aspernatur deleniti qui accusantium voluptas exercitationem.',
              createdAt: '2023-10-01T12:34:56.789Z',
              updatedAt: '2023-10-01T12:34:56.789Z',
              publishedAt: '2023-10-01T12:34:56.789Z',
            },
          },
        },
        {
          data: {
            id: 1,
            attributes: {
              title: 'Melinda',
              subtitle: 'Rerum Tenetur Omnis',
              image: {
                url: 'https://i.pravatar.cc/52',
              },
              text: 'Id dolor illo dolores deserunt maiores architecto sunt omnis omnis quod tenetur sunt nisi sunt nulla.',
              createdAt: '2023-10-01T12:34:56.789Z',
              updatedAt: '2023-10-01T12:34:56.789Z',
              publishedAt: '2023-10-01T12:34:56.789Z',
            },
          },
        },
      ],
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
