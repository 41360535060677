export const DonorStoriesSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: null,
      textAlign: 'center',
      openingTitle: 'donatori e donatrici',
      title:
        'Chi ci <span class="Emphasized">sostiene</span> ogni giorno da sempre',
      paragraph:
        'Ascolta le testimonianze di chi contribuisce ogni volta che può.',
      // testimonialCard: {
      //   data: {
      //     id: 1,
      //     attributes: {
      //       title: 'Aura',
      //       subtitle: 'Impiegata',
      //       image: {
      //         url: 'https://i.pravatar.cc/50',
      //       },
      //       text: 'Da sempre mi impegno a donare ogni mese parte dei miei risparmi perché voglio contribuire per donare una seconda opportunità.',
      //       createdAt: '2023-10-01T12:34:56.789Z',
      //       updatedAt: '2023-10-01T12:34:56.789Z',
      //       publishedAt: '2023-10-01T12:34:56.789Z',
      //     },
      //   },
      // },
      ctaButton: {
        label: 'Diventa donatore',
        url: '/dona',
      },
      // this would be a `slides` data type,
      // which would specify if the content
      // is an image or a video in some way
      slides: [
        {
          type: 'video',
          title: null,
          body: null,
          cta: null,
          video: {
            url: '/assets/videos/v.mp4',
            poster: 'https://picsum.photos/500/500',
            width: 343,
            height: 333,
          },
          testimonialCard: {
            data: {
              id: 1,
              attributes: {
                title: 'Aura',
                subtitle: 'Impiegata',
                image: {
                  url: 'https://i.pravatar.cc/50',
                },
                text: 'Da sempre mi impegno a donare ogni mese parte dei miei risparmi perché voglio contribuire per donare una seconda opportunità.',
                createdAt: '2023-10-01T12:34:56.789Z',
                updatedAt: '2023-10-01T12:34:56.789Z',
                publishedAt: '2023-10-01T12:34:56.789Z',
              },
            },
          },
        },
        {
          type: 'video',
          title: null,
          body: null,
          cta: null,
          video: {
            url: '/assets/videos/v.mp4',
            poster: 'https://picsum.photos/500/501',
            width: 343,
            height: 333,
          },
          testimonialCard: {
            data: {
              id: 2,
              attributes: {
                title: 'Rumor',
                subtitle: 'Impiegata',
                image: {
                  url: 'https://i.pravatar.cc/51',
                },
                text: 'Eheu. Heu, gratis fluctus! Heu, peritus luna! Eheu, brevis xiphias! Magnum, teres diatrias una locus de raptus, ferox danista.',
                createdAt: '2023-10-01T12:34:56.789Z',
                updatedAt: '2023-10-01T12:34:56.789Z',
                publishedAt: '2023-10-01T12:34:56.789Z',
              },
            },
          },
        },
        {
          type: 'video',
          title: null,
          body: null,
          cta: null,
          video: {
            url: '/assets/videos/v.mp4',
            poster: 'https://picsum.photos/500/502',
            width: 343,
            height: 335,
          },
          testimonialCard: {
            data: {
              id: 3,
              attributes: {
                title: 'Civitas',
                subtitle: 'Impiegata',
                image: {
                  url: 'https://i.pravatar.cc/52',
                },
                text: 'Elogiums mori! Alter demissios ducunt ad solitudo. Sunt orgiaes resuscitabo clemens, brevis fideses. Castors sunt vigils de pius nuptia.',
                createdAt: '2023-10-01T12:34:56.789Z',
                updatedAt: '2023-10-01T12:34:56.789Z',
                publishedAt: '2023-10-01T12:34:56.789Z',
              },
            },
          },
        },
      ],
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
