export const ProgressSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: 'dark',
      textAlign: 'center',
      openingTitle: 'a che punto siamo',
      title:
        'Il costante <span class="Emphasized">progresso</span> della ricerca',
      paragraph: 'Ecco come le vostre donazioni contribuiscono alla ricerca.',
      highlightCard: {
        title: 'Proprio ora',
        body: 'I nostri ricercatori e ricercatrici sono impegnati a cercare un modo efficace per prevenire il tumore al seno',
      },
      ctaButton: {
        label: 'Sostieni la ricerca',
        url: '/dona',
      },
      // TODO TextCard…
      image: {
        id: 1,
        name: 'example.jpg',
        alternativeText: 'An example image',
        caption: 'Example caption',
        width: 1200,
        height: 1200,
        // formats: {
        //   thumbnail: {
        //     name: 'thumbnail_example.jpg',
        //     hash: 'thumbnail_example_hash',
        //     ext: '.jpg',
        //     mime: 'image/jpeg',
        //     width: 156,
        //     height: 117,
        //     size: 3.1,
        //     path: null,
        //     url: '/uploads/thumbnail_example_hash.jpg',
        //   },
        //   large: {
        //     name: 'large_example.jpg',
        //     hash: 'large_example_hash',
        //     ext: '.jpg',
        //     mime: 'image/jpeg',
        //     width: 800,
        //     height: 600,
        //     size: 40.5,
        //     path: null,
        //     url: '/uploads/large_example_hash.jpg',
        //   },
        // },
        hash: 'example_hash',
        ext: '.jpg',
        mime: 'image/jpeg',
        size: 45.1,
        url: 'https://picsum.photos/1200/1201',
        previewUrl: null,
        provider: 'local',
        provider_metadata: null,
        created_at: '2023-10-01T12:34:56.789Z',
        updated_at: '2023-10-01T12:34:56.789Z',
      },
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
