export const StoriesSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: null,
      textAlign: 'center',
      paragraphPosition: 'right',
      openingTitle: 'chi ha bisogno di te',
      title:
        'Le <span class="Emphasized">persone</span> a cui hai dato speranza',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      // this would be a `slides` data type,
      // which would specify if the content
      // is an image or a video in some way
      slides: [
        {
          type: 'image',
          title: 'Maria, 52 anni',
          body: 'AIRC è stata al mio fianco nel momento più difficile',
          cta: {
            label: 'La storia di Maria',
            url: '/dona',
          },
          image: {
            id: 1,
            name: 'example.jpg',
            alternativeText: 'An example image',
            caption: 'Example caption',
            width: 1200,
            height: 1200,
            // formats: {
            //   thumbnail: {
            //     name: 'thumbnail_example.jpg',
            //     hash: 'thumbnail_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 156,
            //     height: 117,
            //     size: 3.1,
            //     path: null,
            //     url: '/uploads/thumbnail_example_hash.jpg',
            //   },
            //   large: {
            //     name: 'large_example.jpg',
            //     hash: 'large_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 800,
            //     height: 600,
            //     size: 40.5,
            //     path: null,
            //     url: '/uploads/large_example_hash.jpg',
            //   },
            // },
            hash: 'example_hash',
            ext: '.jpg',
            mime: 'image/jpeg',
            size: 45.1,
            url: 'https://picsum.photos/1200/1201',
            previewUrl: null,
            provider: 'local',
            provider_metadata: null,
            created_at: '2023-10-01T12:34:56.789Z',
            updated_at: '2023-10-01T12:34:56.789Z',
          },
        },
        {
          type: 'image',
          title: 'Roberto, 38 anni',
          body: 'AIRC ha reso possibile il mio percorso di guarigione',
          cta: {
            label: 'La storia di Roberto',
            url: '/dona',
          },
          image: {
            id: 1,
            name: 'example.jpg',
            alternativeText: 'An example image',
            caption: 'Example caption',
            width: 1200,
            height: 1200,
            // formats: {
            //   thumbnail: {
            //     name: 'thumbnail_example.jpg',
            //     hash: 'thumbnail_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 156,
            //     height: 117,
            //     size: 3.1,
            //     path: null,
            //     url: '/uploads/thumbnail_example_hash.jpg',
            //   },
            //   large: {
            //     name: 'large_example.jpg',
            //     hash: 'large_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 800,
            //     height: 600,
            //     size: 40.5,
            //     path: null,
            //     url: '/uploads/large_example_hash.jpg',
            //   },
            // },
            hash: 'example_hash',
            ext: '.jpg',
            mime: 'image/jpeg',
            size: 45.1,
            url: 'https://picsum.photos/1200/1202',
            previewUrl: null,
            provider: 'local',
            provider_metadata: null,
            created_at: '2023-10-01T12:34:56.789Z',
            updated_at: '2023-10-01T12:34:56.789Z',
          },
        },
        {
          type: 'image',
          title: 'Paolo, 42 anni',
          body: 'AIRC ha trasformato la mia storia in un messaggio di speranza',
          cta: {
            label: 'La storia di Paolo',
            url: '/dona',
          },
          image: {
            id: 1,
            name: 'example.jpg',
            alternativeText: 'An example image',
            caption: 'Example caption',
            width: 1200,
            height: 1200,
            // formats: {
            //   thumbnail: {
            //     name: 'thumbnail_example.jpg',
            //     hash: 'thumbnail_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 156,
            //     height: 117,
            //     size: 3.1,
            //     path: null,
            //     url: '/uploads/thumbnail_example_hash.jpg',
            //   },
            //   large: {
            //     name: 'large_example.jpg',
            //     hash: 'large_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 800,
            //     height: 600,
            //     size: 40.5,
            //     path: null,
            //     url: '/uploads/large_example_hash.jpg',
            //   },
            // },
            hash: 'example_hash',
            ext: '.jpg',
            mime: 'image/jpeg',
            size: 45.1,
            url: 'https://picsum.photos/1200/1203',
            previewUrl: null,
            provider: 'local',
            provider_metadata: null,
            created_at: '2023-10-01T12:34:56.789Z',
            updated_at: '2023-10-01T12:34:56.789Z',
          },
        },
        {
          type: 'image',
          title: 'Xyz, 99 anni',
          body: 'AIRC mi ha dato speranza quando tutto sembrava perduto',
          cta: {
            label: 'La storia di Xyz',
            url: '/dona',
          },
          image: {
            id: 1,
            name: 'example.jpg',
            alternativeText: 'An example image',
            caption: 'Example caption',
            width: 1200,
            height: 1200,
            // formats: {
            //   thumbnail: {
            //     name: 'thumbnail_example.jpg',
            //     hash: 'thumbnail_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 156,
            //     height: 117,
            //     size: 3.1,
            //     path: null,
            //     url: '/uploads/thumbnail_example_hash.jpg',
            //   },
            //   large: {
            //     name: 'large_example.jpg',
            //     hash: 'large_example_hash',
            //     ext: '.jpg',
            //     mime: 'image/jpeg',
            //     width: 800,
            //     height: 600,
            //     size: 40.5,
            //     path: null,
            //     url: '/uploads/large_example_hash.jpg',
            //   },
            // },
            hash: 'example_hash',
            ext: '.jpg',
            mime: 'image/jpeg',
            size: 45.1,
            url: 'https://picsum.photos/1200/1204',
            previewUrl: null,
            provider: 'local',
            provider_metadata: null,
            created_at: '2023-10-01T12:34:56.789Z',
            updated_at: '2023-10-01T12:34:56.789Z',
          },
        },
      ],
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
